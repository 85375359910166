<main *ngIf="!userService.isLoggedIn(); else noPermission">
  <div *ngIf="!loading && !userService.isLoggedIn()">
    <form [formGroup]="form">
      <mat-card>
        <mat-card-header>
          <mat-card-title><h1><b>Request password reset</b></h1></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="request-reset-container">
            <mat-form-field appearance="fill">
              <mat-label>Enter your E-Mail address</mat-label>
              <input formControlName="email" matInput required>
              <mat-error *ngIf="form.get('email').invalid">
                <span *ngIf="form.get('email').errors.required">You need to set an E-Mail</span>
                <span *ngIf="form.get('email').errors.email">You need to set a valid E-Mail address</span>
                <span *ngIf="form.get('email').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button type='submit' (click)="requestReset()" mat-raised-button color="warn" [disabled]="form.invalid || loading">Request reset <mat-icon>loop</mat-icon></button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</main>

<ng-template #noPermission>
  <app-no-permission *ngIf="!loading"></app-no-permission>
</ng-template>

<div class="requesting-overlay" *ngIf="loading">
  <mat-spinner color="primary" style="margin-left: 40%; margin-top: 15%;"></mat-spinner>
</div>
