<main>
  <h1 style="font-size: 2em; margin-bottom: 50px;">Welcome <span *ngIf="this.user">{{this.user.username}}&nbsp;</span>to <strong>𝓠𝓾𝓲𝔃𝓕𝓲𝓰𝓱𝓽</strong></h1>
  <p class="info-text"><b>"Two things are infinite, the universe and human stupidity, but I'm not quite sure about the universe yet." <br />~Albert Einstein</b><br /><br />Can you proof the opposite? Here you get the chance to
  QuizFight is a game, created by 3 guys. If you click on 'play', you'll get into a queue, waiting for a worthy opponent. If you find one, you will get redirected into a match. Both of you will get the same questions. If you answer right
  you can deal damage to your opponent. The damage depends on your equipment, you can customize in your inventory with items you can get from lootboxes which you can get by playing. Anyway, if the HP of a player fell to ZERO, that player lost.
  <br /><br />And please be fair and prevent using things like Google!</p>
  <button mat-raised-button class="join-button" (click)="insertIntoQueue()" routerLink="/queue" [disabled]="!this.userService.isLoggedIn()" color="primary">Play now! <mat-icon>play_arrow</mat-icon></button> <br/>
  <ngb-alert [type]="'danger'" *ngIf="!this.userService.isLoggedIn()" [dismissible]="false">You need to be logged in to play the game!</ngb-alert><br />
  <p class="info-text" style="margin-bottom: 100px;">
    <span style="font-size: 2em"><u>Fun-Fact</u></span><br /><br />
    {{fact}}
  </p><br />
  <table *ngIf="this.top != null" class="table table-dark table-striped call-table">
    <thead>
    <tr style="text-align: center">
      <th style="text-align: left">Rank</th>
      <th style="text-align: left">Username</th>
      <th style="text-align: left">Correct answers (all time)</th>
      <th style="text-align: left">Games played</th>
      <th style="text-align: left">Games won</th>
    </tr>
    </thead>
    <tbody>
    <tr style='text-align: center' *ngFor="let user of top.slice(topStartIndex, topEndIndex)">
      <td style="text-align: left"><mat-chip-list>
        <mat-chip
          [class.first]="top.indexOf(user)+1 === 1"
          [class.second]="top.indexOf(user)+1 === 2"
          [class.third]="top.indexOf(user)+1 === 3"
          [class.other-rank]="top.indexOf(user)+1 > 3">#{{ top.indexOf(user)+1 }}
        </mat-chip></mat-chip-list></td>
      <td style="text-align: left">{{ user.username }}</td>
      <td style="text-align: left">{{ user.allTimeCorrect }}</td>
      <td style="text-align: left">{{ user.gamesPlayed }}</td>
      <td style="text-align: left">{{ user.gamesWon }}</td>
    </tr>
    </tbody>
  </table>
  <mat-paginator
    #topPaginator
    *ngIf="top"
    [pageSizeOptions]="[5, 10, 20, 30, 40, 50, 75, 100]"
    [length]="top.length"
    [pageSize]="20"
    (page)="pageChange($event)"
    showFirstLastButtons style="width: 90%; margin: auto; background-color: #1d1d1d; color: white;"></mat-paginator>
</main>
