<main *ngIf="!userService.isLoggedIn(); else noPermission">
  <div *ngIf="!loading && !userService.isLoggedIn()">
    <form [formGroup]="form">
      <mat-card>
        <mat-card-header>
          <mat-card-title><h1><b>Register</b></h1></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="login-container">
            <mat-form-field appearance="fill">
              <mat-label>Enter your E-Mail address</mat-label>
              <input formControlName="email" matInput required>
              <mat-error *ngIf="form.get('email').invalid">
                <span *ngIf="form.get('email').errors.required">You need to set an E-Mail</span>
                <span *ngIf="form.get('email').errors.email">You need to set a valid E-Mail address</span>
                <span *ngIf="form.get('email').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Choose your username</mat-label>
              <input formControlName="username" matInput required>
              <mat-error *ngIf="form.get('username').invalid">
                <span *ngIf="form.get('username').errors.required">You need to set a username</span>
                <span *ngIf="form.get('username').errors.minlength">You need to set at least 3 characters</span>
                <span *ngIf="form.get('username').errors.maxlength">You can just set 16 characters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Choose your password</mat-label>
              <input formControlName="password" type="password" matInput required>
              <mat-error *ngIf="form.get('password').invalid">
                <span *ngIf="form.get('password').errors.required">You need to set a password</span>
                <span *ngIf="form.get('password').errors.minlength">You need to set at least 5 characters</span>
                <span *ngIf="form.get('password').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Type in your password again</mat-label>
              <input formControlName="confirmPassword" type="password" matInput required>
              <mat-error *ngIf="form.get('confirmPassword').invalid">
                <span *ngIf="form.get('confirmPassword').errors.required">You need to set a password</span>
                <span *ngIf="form.get('confirmPassword').errors.minlength">You need to set at least 5 characters</span>
                <span *ngIf="form.get('confirmPassword').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
          </div>
          <a href="" routerLink="/login">Already have an account?</a>
        </mat-card-content>
        <mat-card-actions>
          <button type='submit' (click)="register()" mat-raised-button color="primary" [disabled]="form.invalid">Register <mat-icon>person_add</mat-icon></button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</main>

<ng-template #noPermission>
  <app-no-permission *ngIf="!loading"></app-no-permission>
</ng-template>

<div class="register-overlay" *ngIf="loading">
  <mat-spinner color="primary" style="margin-left: 40%; margin-top: 15%;"></mat-spinner>
</div>
