<main *ngIf="!userService.isLoggedIn(); else noPermission">
  <div *ngIf="!loading && !userService.isLoggedIn()">
    <form [formGroup]="form">
      <mat-card>
        <mat-card-header>
          <mat-card-title><h1><b>Request password reset</b></h1></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="reset-password-container">
            <mat-form-field appearance="fill">
              <mat-label>Your token</mat-label>
              <input formControlName="token" matInput required>
              <mat-error *ngIf="form.get('token').invalid">
                <span *ngIf="form.get('token').errors.required">You need to set a token</span>
                <span *ngIf="form.get('token').errors.minLength">Tokens normally have a length of 25 characters</span>
                <span *ngIf="form.get('token').errors.maxlength">Tokens normally have a length of 25 characters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Choose your password</mat-label>
              <input formControlName="password" type="password" matInput required>
              <mat-error *ngIf="form.get('password').invalid">
                <span *ngIf="form.get('password').errors.required">You need to set a password</span>
                <span *ngIf="form.get('password').errors.minlength">You need to set at least 5 characters</span>
                <span *ngIf="form.get('password').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Type in your password again</mat-label>
              <input formControlName="confirmPassword" type="password" matInput required>
              <mat-error *ngIf="form.get('confirmPassword').invalid">
                <span *ngIf="form.get('confirmPassword').errors.required">You need to set a password</span>
                <span *ngIf="form.get('confirmPassword').errors.minlength">You need to set at least 5 characters</span>
                <span *ngIf="form.get('confirmPassword').errors.maxlength">You can just set 255 characters</span>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button type='submit' (click)="requestReset()" mat-raised-button color="warn" [disabled]="form.invalid">Request reset <mat-icon>loop</mat-icon></button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</main>

<ng-template #noPermission>
  <app-no-permission *ngIf="!loading"></app-no-permission>
</ng-template>

<div class="requesting-overlay" *ngIf="loading">
  <mat-spinner color="primary" style="margin-left: 40%; margin-top: 15%;"></mat-spinner>
</div>
