<mat-sidenav-container class="navbar-container">
  <mat-sidenav opened mode="side">
    <button routerLink="/" matTooltip="Home" matTooltipPosition="left" mat-icon-button aria-label="Go to the homepage">
      <mat-icon>home</mat-icon>
    </button><br />
    <button disabled routerLink="/" matTooltip="Shop (Coming soon)" matTooltipPosition="left" mat-icon-button aria-label="Open the Shop">
      <mat-icon>shopping_basket</mat-icon>
    </button><br />
    <div *ngIf="this.userService.isLoggedIn()">
      <button routerLink="lootbox" matTooltip="Lootbox" matTooltipPosition="left" mat-icon-button aria-label="Have a look at your lootboxes">
        <mat-icon>business_center</mat-icon>
      </button><br />
    </div>
    <div *ngIf="this.userService.isLoggedIn()">
      <button disabled routerLink="inventory" matTooltip="Inventory" matTooltipPosition="left" mat-icon-button aria-label="Open your Inventory">
        <mat-icon>widgets</mat-icon>
      </button><br />
    </div>
    <div *ngIf="this.userService.isLoggedIn() && isAdmin">
      <button routerLink="/admin" class="admin-area" matTooltip="Administration" matTooltipPosition="left" mat-icon-button aria-label="Admin area">
        <mat-icon>font_download</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="to-bottom">
      <mat-divider></mat-divider>
      <div *ngIf="!this.userService.isLoggedIn()">
        <button routerLink="register" matTooltip="Register" matTooltipPosition="left" mat-icon-button aria-label="Create a new account">
          <mat-icon>person_add</mat-icon>
        </button><br />
      </div>
      <div *ngIf="!this.userService.isLoggedIn()">
        <button routerLink="login" matTooltip="Login" matTooltipPosition="left" mat-icon-button aria-label="Login into an existing account">
          <mat-icon>fingerprint</mat-icon>
        </button><br />
      </div>
      <div *ngIf="this.userService.isLoggedIn()">
        <button (click)="this.userService.logout()" routerLink="logout" matTooltip="Logout" matTooltipPosition="left" mat-icon-button aria-label="Logout">
          <mat-icon>exit_to_app</mat-icon>
        </button><br />
      </div>
      <div *ngIf="this.userService.isLoggedIn()">
        <button routerLink="userdetails" matTooltip="Userdetails" matTooltipPosition="left" mat-icon-button aria-label="Change your userdata">
          <img [draggable]="false" [src]="this.imageSrc" alt="" style="border-radius: 100%; max-width: 35px; max-height: 35px; min-height: 35px">
        </button><br />
      </div>
      <div *ngIf="this.userService.isLoggedIn()">
        <button style="color: yellow; font-size: 11px;" matTooltip="You have {{money}} Coins" matTooltipPosition="left" mat-icon-button aria-label="Your money">
          {{money}}<mat-icon>attach_money</mat-icon>
        </button><br />
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet>
      <div class="loading-overlay" *ngIf="loading">
        <mat-spinner color="primary" style="margin-left: 40%; margin-top: 15%;"></mat-spinner>
      </div>
    </router-outlet>
<!--    <app-footer *ngIf="!this.loading"></app-footer>-->
  </mat-sidenav-content>
</mat-sidenav-container>
