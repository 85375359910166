<div *ngIf="this.userService.isLoggedIn() && this.currentUser; else noPermission" class="user-details-parent">
  <main>
    <h1 style="font-size: 4em; margin-bottom: 100px; line-height: 1em;"><b>User-Details</b></h1>
    <mat-card class="user-details">
      <div class="image">
        <img [draggable]="false" [src]="this.imageSrc" alt="your profile picture">
        <input type="file" (change)="picked($event)" id="picture">
      </div>
      <div class="second-part">
        <div class="hr-top">
          <h1><b>{{ this.currentUser.username }}</b></h1>
          <mat-chip-list>
            <mat-chip
            [class.first]="rank === 1"
            [class.second]="rank === 2"
            [class.third]="rank === 3"
            [class.other-rank]="rank > 3">Rank #{{this.rank}}</mat-chip>
          </mat-chip-list>
        </div>
        <hr>
        <div class="hr-bottom">
          <div class="user-data">
            <h2><b>Some data:</b></h2><br />
            <span class="data">E-Mail: {{ this.currentUser.email }}</span><br />
            <span class="data">Money: {{ this.currentUser.money }}$</span><br /><br />
            <h2><b>Your stats:</b></h2><br />
            <span class="statistic">All time correct: {{ this.currentUser.allTimeCorrect }}</span><br />
            <span class="statistic">Games played: {{ this.currentUser.gamesPlayed }}</span><br />
            <span class="statistic">Games won: {{ this.currentUser.gamesWon }}</span><br />
          </div>
          <div class="options">
              <button (click)="submitImage()" mat-fab style="background-color: limegreen; margin-left: 10px;" aria-label="upload image">
                <mat-icon>file_upload</mat-icon>
              </button><br />
            <button (click)="openEditingModal(this.currentUser)" mat-fab style="background-color: orange" aria-label="change email">
              <mat-icon>edit</mat-icon>
            </button><br />
            <button (click)="openPasswordChangeModal(this.currentUser)" mat-fab style="background-color: mediumpurple" aria-label="change pw">
              <mat-icon>lock</mat-icon>
            </button><br /><br />
            <button (click)="deleteUser()" mat-fab color="warn" aria-label="delete user">
              <mat-icon>delete</mat-icon>
            </button><br />
          </div>
        </div>
      </div>
    </mat-card>
  </main>
</div>

<ng-template #noPermission>
  <app-no-permission></app-no-permission>
</ng-template>
